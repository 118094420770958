import { Image } from 'antd'
import React from 'react'
import LOGO from '../../assets/images/logo.png'
const Home = () => {
  return (
    <div style={{
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: '100%',
      flexDirection: 'column',
      backgroundColor: "#3A3A41",
      borderRadius: 10,
      
    }}>
      <h1 style={{
        color: "#fff",
        fontWeight: "bold",
      }}>
        Rushd Consulting
      </h1>
      <Image 
        src={LOGO}
        preview={false}
        style={{
          width: 200,
          height: 200,
          objectFit: 'contain'
        
        }}
      />
    </div>
  )
}

export default Home