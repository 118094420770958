const COLORS = {
    primary: '#000000',
    lightBlue: '#C79648',
    gray: '#EFF2F5',
    white: "#FFFFFF",
    black: '#212121',
    danger: '#FC4C3A',
    darkGray: '#dddddd',
    lightGray: '#f0f2f5',
    warning: '#ffbf00',
    success: '#00924c'

}

export default COLORS;