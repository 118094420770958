const messages = {
  ar: {
    home: "الرئيسية",
    tables: "الطاولات",
    oppLanguage: "English",
    arabic: "العربية",
    english: "الإنجليزية",
    signout: "تسجيل الخروج",
    settings: "الإعدادات",
    signin: "تسجيل الدخول",
    signup: "إنشاء حساب",
    name: "ادخل اسمك",
    phone: "رقم الجوال",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gmail.com",
    email: "البريد الالكتروني",
    error: "خطأ",
    errorname: "يجب كتابة الاسم",
    errorphone: "خطأ في الرقم",
    errorpassword: "الرقم السري غير صحيح",
    password: "كلمة المرور",
    loadingData: "جاري التحميل",
    whtsapp: "الواتساب",
    send: "إرسال",
    users: "المستخدمين",
    addName: "إضافة الاسم",
    justName: "الاسم",
    message: "الرسالة",
    addAttachment: "إضافة مرفق",
    whatsappSettings: "إعدادات الواتساب",
    uploadExcel: "رفع ملف اكسل",
    upload: "رفع",
    savedSuccessfully: "تم الحفظ بنجاح",
    startSuccess: "تم بدء العملية بنجاح",
    sendAsCaption: "إرسال الكلام كتعليق على الصورة",
  },
  en: {
    home: "Home",
    sendAsCaption: "Send as caption",
    startSuccess: "Start Success",
    savedSuccessfully: "Saved Successfully",
    upload: "Upload",
    uploadExcel: "Upload Excel",
    whatsappSettings: "Whatsapp Settings",
    addAttachment: "Add Attachment",
    message: "Message",
    send: "Send",
    users: "Users",
    addName: "Add Name",
    justName: "Name",
    whtsapp: "Whatsapp",
    loadingData: "Loading",
    password: "password",
    errorphone: "error Phone",
    errorpassword: "Password is wrong",
    tables: "Tables",
    oppLanguage: "عربي",
    arabic: "Arabic",
    english: "English",
    signout: "Sign out",
    settings: "Settings",
    signin: "Sign in",
    signup: "Sign up",
    name: "Enter your name",
    phone: "Phone number",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gamil.com",
    email: "Email",
    error: "Error",
    errorname: "Name is required",
  },
};

export default messages;
