import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Spin,
  Form,
  Input, Upload,
  Button,
  Space,
  Switch
} from "antd";
import { useIntl } from "react-intl";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../Components/CustomButton";
import { sendMessages, sendMessageWithImage } from "../../API/fetch";
import openNotification from "../../Components/Notifications";
import { Table } from "ant-table-extensions";
import * as XLSX from "xlsx";

const { Dragger } = Upload;

const { Text, Title } = Typography;
const Marketing = () => { 
  const intl = useIntl();
  const { rtl } = useSelector((state) => state.settings);
  // const { students } = useSelector((state) => state.students);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [file, setFile] = useState(null);
  const [formatted, setFormatted] = useState([]);
  // useEffect(() => {
  //   if (!students?.length) {
  //     // getUsersData();
  //   } else {
  //     let f = students.map((student) => {
  //       return {
  //         key: student.name,
  //         name: student.name,
  //         phone: student.phone,
  //         grade: student?.grade ? student?.grade : "غير محدد",
  //       };
  //     });
  //     setFormatted(f);
  //   }
  // }, [students]);
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 80,
      render: (number, record, index) => {
        return <Text>{index + 1}</Text>;
      },
      // filter selected rows;
      onFilter: (value, record) => {
        let found = phones.findIndex((p) => p.phone === record.phone) !== -1;
        if (value === "TRUE") {
          return found;
        } else {
          return !found;
        }
      },
      filters: [
        {
          text: "تم اختيارهم",
          value: "TRUE",
        },
        {
          text: "لم يتم اختيارهم",
          value: "FALSE",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
      width: 160,
      render: (name, record) => {
        return <Text>{name}</Text>;
      },
      sorter:(a,b)=>a.name.localeCompare(b.name),
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        return <Text>{phone}</Text>;
      },
    },
    
  ];

  const dummyRequest = (props) => {
    setTimeout(() => {
      props.onSuccess("ok");
      setFile(props.file);
    }, 0);
  };
  const sendSMS = async (values) => {
    try {
      if (!phones?.length) return;
      if (file) {
        return sendWithImage(values, file);
      }
      let formattedMessageWithPhone = phones.map((phone) => {
        let msg = values.message;
        // replace the name
        if(phone.name && msg.includes("{name}")){
          msg = msg.replace("{name}", phone.name);
        }
        return {
          phone: phone.phone,
          message: msg,
        };
      });
      setLoading(true);
      const data = {
        phones: formattedMessageWithPhone,
        senderName: "Whatsapp",
      };
      openNotification({
        title: intl.formatMessage({ id: "startSuccess" }),
        type: "warning",
        description: `سيستغرق ارسال الرسائل ${data.phones.length} ثانية`,
        rtl,
        
      });
      const res = await sendMessages(data);
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const sendWithImage = async (values, file) => {
    try {
      // send the image with the message;
      if (!phones?.length) return;
      // loop throw the phones and send the message;
      setLoading(true);
      let formattedMessageWithPhone = phones.map((phone) => {
        let msg = values.message;
        // replace the name
        if(phone.name && msg.includes("{name}")){
        msg = msg.replace("{name}", phone.name);
        }
        return {
          phone: phone.phone,
          message: msg,
        };
      });
      let sendAsCaption = "false";
      if(values.sendAsCaption){
        sendAsCaption = "true";
      }
      const formData = new FormData();
      formData.append("phones", JSON.stringify(formattedMessageWithPhone));
      formData.append("sendAsCaption", sendAsCaption);
      formData.append("file", file);
      const res = await sendMessageWithImage(formData);
      console.log(res.data)
      if (res.data.status === "success") {
        form.resetFields();
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          description: `تم إرسال الرسالة إلى ${res?.data?.countOfSent} رقم`,
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPhones(selectedRows);
      // setPhones(phones);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.phone ? true : false,
        // Column configuration not to be checked
        // check: record.phone,
      };
    },
  };

  const onChange = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      let formatted = [];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      // format should be like this ==> 966509111136
      dataParse.forEach((arr, index) => {
        if (index === 0) return;
        // console.log(arr)
        if (arr[0] && arr[1]  ) {
          let phone = `${arr[1]}`;
          console.log(phone.length)
              formatted.push({
                name: arr[0],
                phone: phone.length <=9 ? `966${arr[1]}` : `${arr[1]}`,
                key: `${arr[1]}-${index}`
              });
        }
      });
      setFormatted(formatted);
    };
    reader.readAsBinaryString(f);
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <Spin spinning={loading}>
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        > 
          <Title
            level={4}
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {intl.formatMessage({ id: "users" })}
          </Title>
          <Space direction="horizontal" >
          <Button
            className='upload-wrap  border-8'
            onClick={() => {
              const a = document.getElementById("my-upload-excel");
              a.click();
            }}
          >
            <input
              style={{ display: "none" }}
              className='file-uploader'
              type='file'
              id='my-upload-excel'
              accept='.xlsx, .xls'
              onChange={onChange}
            />
            <span className='upload-text'>{intl.formatMessage({id: 'uploadExcel'})}</span>
          </Button>
          
          </Space>
          
        </div>
        <Form
          name='send-sms'
          form={form}
          preserve={false}
          className='form-container'
          layout='vertical'
          onFinish={sendSMS}
          initialValues={{
            senderName: "Whatsapp",
          }}
        >
          {() => {
            return (
              <>
              <CustomButton 
                text={intl.formatMessage({ id: "addName" })}
                type="secondary"
                onClick={() => {
                  let text = form.getFieldValue("message");
                  text += " {name} ";
                  form.setFieldsValue({ message: text });
                }}
              />
                <Form.Item
                  name='message'
                  label={intl.formatMessage({ id: "message" })}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  name='sendAsCaption'
                  label={intl.formatMessage({ id: "sendAsCaption" })}
                  valuePropName='checked'
                  
                >
                  <Switch 
                    disabled={!file}
                  />
                </Form.Item>

                {/* Add File upload input image */}
                <Text className='my-font'> {intl.formatMessage({id: 'addAttachment'})}</Text>
                <Dragger
                  accept='image/jpg,image/jpeg,image/png,application/pdf'
                  // action='/upload.do'
                  listType='picture-card'
                  customRequest={dummyRequest}
                  maxCount={1}
                >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      {intl.formatMessage({id: 'upload'})}
                    </div>
                  </div>
                </Dragger>

                <Form.Item>
                  <CustomButton
                    htmlType='submit'
                    text={intl.formatMessage({ id: "send" })}
                    className={`btnRegister btn-text border-8`}
                    loading={loading}
                    disabled={
                      loading ||
                      !phones?.length ||
                      !form?.getFieldValue("message")
                    }
                    type='primary'
                  />
                </Form.Item>
              </>
            );
          }}
        </Form>

        <Table
          searchable
          fuzzySearch
          searchableProps={{
            debounce: true,
            fuzzySearch: true,
            inputProps: {
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
              disabled: loading || formatted?.length === 0,
            },
          }}
          pagination={false}
          columns={columns}
          className='light-shadow'
          loading={loading}
          dataSource={formatted}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Spin>
    </div>
  );
};

export default Marketing;
