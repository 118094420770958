import phoneCods from "../Views/Auth/PhoneCods.json"


export function hasDuplicates(array) {
  return (new Set(array)).size !== array.length;
}

export const checkIfValidPhone = (phone) => {
 
    let isValid = false;
    phone = phone.replace("+", '')
    // console.log(phone)
      if(`${phone[0]}` === '7'){
          if (`${phone.substring(1)}`.length === 9){
            isValid = true;
          }
      }else if(`${phone[0]}` === "1") {
        if (`${phone.substring(1)}`.length === 10){
            isValid = true;
          }
        
      }else {
        let towDigits = phone.substring(0,2)
        let threeDigits = phone.substring(0,3);
        let i = phoneCods.findIndex(a => a.dial_code === `+${towDigits}`)
        if(i !== -1){
          isValid = true;
        }else {
        if(`${threeDigits}` === `966`){
            if(`${phone.substring(3)}`.length === 9 && `${phone[3]}` === '5'){
                isValid = true;
            }
        }else{
            isValid = true;
        }
        }
      }
      return isValid;
  
  }


  export const getDiscountAmount = ({total, percentage, maximumAmount}) => {
    let totalDiscount = total * (percentage / 100); 
    if(Number(maximumAmount) && maximumAmount > 0){
        if(totalDiscount > maximumAmount){
            totalDiscount = maximumAmount;
        }
    }
    return totalDiscount;
  }

  export const getOldestOpenOrder = (orders=[]) => {
    let time = null;
    const openOrders = orders.filter(o => o.status === 'open');
    if(openOrders.length){
      const sorted = openOrders.sort((o1, o2) => new Date(o1.createdAt) - new Date(o2.createdAt))
      time = sorted[0].createdAt;
    }
    return time
  }

  export function cutText(text, length) {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
   let last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "...";
}


export function getContrastYIQ(hexcolor){
  if(!hexcolor || !hexcolor?.trim()?.length) return 'black';
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}


export const getCommentsFromFoods = (foods) => {
  if(!foods || foods?.length === 0) return [];
  const comments = [];
  foods?.forEach((food) => {
    if (food?.comments?.length) {
      food.comments.forEach((comment) => {
        comments.push({
          status: comment?.status,
          comment: comment?.text,
          origin: comment?.origin,
          customerName: comment?.customerId?.name,
          time: new Date(
            comment?.createdAt ? comment.createdAt : new Date()
          ).toLocaleString(),
        });
      });
    }
  });
  return comments;

}

