import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { message } from "antd";
import Register from "./Views/Auth/index";
import Sidebar from "./Components/Sidebar";
import PrivateRoute from "./Components/PrivateRoute";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Home from "./Views/Home";
import Marketing from "./Views/Marketing";
import SettingWhatsaap from "./Views/SettingWhatsaap";



const AllRoutes = () => {
  const intl = useIntl();
  const { isOffline } = useSelector((state) => state.settings);

  useEffect(() => {
    if (isOffline) {
      message.error(intl.formatMessage({ id: "pleaseCheckInternet" }), 0);
    } else {
      message.destroy();
    }
  }, [isOffline]);

 

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Sidebar component={<Home />} />
          </PrivateRoute>
        }
      />
      
      
      <Route
        path='/whtsapp'
        element={
          <PrivateRoute>
            <Sidebar component={<Marketing />} />
          </PrivateRoute>
        }
      />
      
       <Route
        path='/setting'
        element={
          <PrivateRoute>
            <Sidebar component={<SettingWhatsaap />} />
          </PrivateRoute>
        }
      />
      <Route path='*' element={<Register />} />
    </Routes>
  );
};

export default AllRoutes;
