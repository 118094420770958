import React, { useState } from "react";
import { Col, Row, Image, Form, Input, Typography } from "antd";
import { useIntl } from "react-intl";
import LOGO from "../../../assets/images/logo.png";
import PlaceHolder from "../../../assets/images/logo.png";
import ICON from "../../../assets/images/logo.png";
import CustomButton from "../../../Components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguageAction } from "../../../redux/reducers/settingsReducer";
import {
  loginAction,
  logOutAction,
  setUserHasLevelAction,
} from "../../../redux/reducers/authReducer";
import { getBranchData, postCode } from "../../../API/fetch";
import { useNavigate } from "react-router";
import openNotification from "../../../Components/Notifications/index";


const { Text } = Typography;

const EnterCode = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const onFinish = async (e) => {
    setLoading(true);

    try {
     dispatch(setUserHasLevelAction(true))
     history('/')
    } catch (error) {
      if (error?.response?.data?.error === "Code is not valid") {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorCode" }),
          type: "error",
          rtl,
        });
      } else {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({ id: "errorfetch" }),
          type: "error",
          rtl,
        });
      }
    }
  };

  return (
    <Row style={{ minHeight: "100vh" }}>
      <Col
        xs={24}
        sm={12}
        type='flex'
        justify='center'
        align='center'
        direction='column'
        className={"authRightSide"}
      >
        <div className='changeLanguage'>
          <CustomButton
            text={intl.formatMessage({ id: "oppLanguage" })}
            className={`btn-text border-8 px-5 pb-5`}
            loading={false}
            type='primary'
            onClick={() => {
              dispatch(changeLanguageAction());
            }}
          />
        </div>
        <Row type='flex' justify='center' align='middle'>
          <Col span={18}>
            <Row justify='center'>
              <Image
                preview={false}
                width={300}
                src={LOGO}
                className='logo-Login'
              />
            </Row>

            <Form
              name='basic'
              form={form}
              className='form-container'
              layout='vertical'
              onFinish={onFinish}
            >
              <Row justify='center' align='center' style={{ width: "100%" }}>
                <Col span={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "code" })}
                    className='mb-1'
                    name='pinCode'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "errorCode" }),
                      },
                    ]}
                  >
                    <Input autoFocus={true} className={"input"} />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify='center' align='middle'>
                <Col span={24}>
                  <Form.Item>
                    <CustomButton
                      htmlType='submit'
                      text={intl.formatMessage({ id: "enter" })}
                      className={`btnRegister btn-text border-8`}
                      loading={loading}
                      type='primary'
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Text
                type='danger'
                className='pointer my-font'
                onClick={() => dispatch(logOutAction())}
              >
                {intl.formatMessage({ id: "signout" })}
              </Text>
            </Form>
          </Col>
        </Row>
      </Col>
      {/* <Col xs={0} sm={12} type='flex' className={"authLeftSide"}>
        <Image
          preview={false}
          src={ICON}
          style={{ maxWidth: 150, marginBottom: 50,  }}
        />
        <Image preview={false} src={PlaceHolder} width={"70%"} />
      </Col> */}
    </Row>
  );
};

export default EnterCode;
