import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getWhatsappQr = () => {
  return api.get(`/getqr`);
};

export const checkAuth = () => {
  return api.get(`/checkauth`);
};

export const sendMessages = (data) => {
  return api.post(`/messages-numbers`, data, {timeout: 5000000});
};

export const sendMessageWithImage = (data) => {
  return api.post(`/messages-numbers-with-image`, data, {
    timeout: 5000000,
    headers: {
      "Content-Type": "multipart/form-data",
    }
    
  });
};

export const resetWhatsaapQr = () => {
  return api.get(`/resetqr`, {timeout: 1000*60*10});
};

export const getAdminData = () => {
  return api.get(`/admin-data`);
};

export const getRestaurants = () => {
  return api.get(`/admin-restaurants`);
};
